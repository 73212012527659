import gsap from 'gsap';

export const lerp = (x, y, t) => {
  return (1 - t) * x + t * y;
};

export function lerpPrecise(start, end, t = 0.5, limit = 0.001) {
  const v = start * (1 - t) + end * t;
  return Math.abs(end - v) < limit ? end : v;
}

export function damp(a, b, smoothing = 0.5, dt) {
  return lerp(a, b, 1 - Math.exp(-smoothing * 0.05 * dt));
}

export function dampPrecise(a, b, smoothing = 0.5, dt, limit) {
  return lerpPrecise(a, b, 1 - Math.exp(-smoothing * 0.05 * dt), limit);
}

export const map = (value, start1, stop1, start2, stop2) => {
  return start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1));
};

export const round = (x, d) => {
  return Number(x.toFixed(d));
};

export const interpolate = (start, end, value) => {
  return start * (1.0 - value) + end * value;
};

export const clamp = (value, min, max) => {
  return Math.min(Math.max(value, min), max);
};

export const random = (min, max) => {
  return Math.random() * (max - min) + min;
};

export const delay = ms => {
  return new Promise(res => gsap.delayedCall(ms / 1000, res));
};
