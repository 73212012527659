import { CRTFilter } from '@pixi/filter-crt';
import { Detection } from '@classes/Detection';
import GSAP from 'gsap';
import { Graphics } from 'pixi.js';
import { Viewport } from 'pixi-viewport';
import { easeInOutCubic } from '@utils/easing';
import { gui } from '@classes/GUI';
import state from '@store/index';

export default class Controls extends Viewport {
  constructor({ pixiApp, sizes }) {
    super({
      screenWidth: pixiApp.screen.width,
      screenHeight: pixiApp.screen.height,
      worldWidth: sizes.environment.width,
      worldHeight: sizes.environment.height,
      events: pixiApp.renderer.events,
      threshold: 3,
    });

    this.pixiApp = pixiApp;
    this.sizes = sizes;

    this.speed = 0.1;
    this.isDragging = false;
    this.minus = document.querySelector('.minus');
    this.plus = document.querySelector('.plus');

    this.createViewport();
    this.createShader();
    this.createMask();
    this.setEvents();

    this.pixiApp.stage.addChild(this);

    if (gui) this.createDebug();
  }

  createMask() {
    this.mask = new Graphics()
      .beginFill(0xffffff)
      .drawCircle(
        this.pixiApp.screen.width / 2,
        this.pixiApp.screen.height / 2,
        0,
      )
      .endFill();

    this.pixiApp.stage.addChild(this.mask);
  }

  createShader() {
    if (Detection.isMobile) return;
    this.shader = new CRTFilter({
      curvature: 4,
      lineWidth: 1,
      lineContrast: 0.15,
      verticalLine: false,
      noise: 0.1,
      noiseSize: 1,
      vignetting: 0.3,
      vignettingAlpha: 1,
      vignettingBlur: 0.3,
      time: 1,
      seed: 0,
    });

    this.filters = [this.shader];

    this.pixiApp.ticker.add(() => {
      this.shader.time += this.speed;
    });
  }

  createViewport() {
    const viewportWidth = this.pixiApp.screen.width;
    const viewportHeight = this.pixiApp.screen.height;

    this.minScale = this.findCover(
      this.sizes.environment.width,
      this.sizes.environment.height,
    );

    this.maxScale = this.minScale * 3;

    this.clamp({
      left: (viewportWidth - this.sizes.environment.width) / 2,
      right: (viewportWidth + this.sizes.environment.width) / 2,
      top: (viewportHeight - this.sizes.environment.height) / 2,
      bottom: (viewportHeight + this.sizes.environment.height) / 2,
    });

    this.clampZoom({
      minScale: this.minScale,
      maxScale: this.maxScale,
    });

    this.setZoom(this.maxScale, true);

    this.moveCenter(window.innerWidth / 2, window.innerHeight / 2);
  }

  handleZoom(zoom) {
    const stepSize = (this.maxScale - this.minScale) / 3;
    const zoomSteps = [
      this.minScale,
      this.minScale + stepSize,
      this.maxScale - stepSize,
      this.maxScale,
    ];

    let currentZoomIndex = zoomSteps.findIndex(
      zoomLevel => this.scaled <= zoomLevel,
    );

    if (currentZoomIndex === -1) {
      currentZoomIndex = zoomSteps.length - 1;
    }

    if (zoom === 'minus' && currentZoomIndex > 0) {
      currentZoomIndex--;
    } else if (zoom === 'plus' && currentZoomIndex < zoomSteps.length - 1) {
      currentZoomIndex++;
    }

    const newZoomLevel = zoomSteps[currentZoomIndex];

    this.animate({
      time: 400,
      ease: 'easeOutCirc',
      scale: newZoomLevel,
      callbackOnComplete: () => {
        state.zoomLevel =
          (newZoomLevel - this.minScale) / (this.maxScale - this.minScale);
      },
    });
  }

  show() {
    this.animate({
      time: 3000,
      ease: 'easeInOutCubic',
      scale: this.minScale,
      callbackOnComplete: () => {
        const viewportWidth = this.pixiApp.screen.width;
        const viewportHeight = this.pixiApp.screen.height;
        this.drag()
          .decelerate()
          .pinch()
          .wheel()
          .clamp({
            left: (viewportWidth - this.sizes.environment.width) / 2,
            right: (viewportWidth + this.sizes.environment.width) / 2,
            top: (viewportHeight - this.sizes.environment.height) / 2,
            bottom: (viewportHeight + this.sizes.environment.height) / 2,
          });
      },
    });

    const radius = Math.sqrt(
      Math.pow(this.pixiApp.screen.width / 2, 2) +
        Math.pow(this.pixiApp.screen.height / 2, 2),
    );

    const circle = {
      radius: 0,
    };

    GSAP.to(circle, {
      radius: radius,
      duration: 3,
      ease: easeInOutCubic,

      onUpdate: () => {
        this.mask
          .clear()
          .beginFill(0xffffff)
          .drawCircle(
            this.pixiApp.screen.width / 2,
            this.pixiApp.screen.height / 2,
            circle.radius,
          )
          .endFill();
      },
    });
  }

  setEvents() {
    this.minus.addEventListener('click', () => this.handleZoom('minus'));
    this.plus.addEventListener('click', () => this.handleZoom('plus'));

    this.on('zoomed', () => {
      const percent =
        (this.scaled - this.minScale) / (this.maxScale - this.minScale);
      state.zoomLevel = percent;
    });

    this.on('drag-start', () => {
      this.isDragging = true;
    });

    this.on('drag-end', () => {
      this.isDragging = false;
    });
  }

  onResize() {
    this.resize(
      this.pixiApp.screen.width,
      this.pixiApp.screen.height,
      4096,
      2340,
    );

    this.minScale = this.findCover(4096, 2340);
    this.maxScale = this.minScale * 3;
    this.clampZoom({
      minScale: this.minScale,
      maxScale: this.maxScale,
    });

    this.fitWorld(true);
  }

  createDebug() {
    // VIEWPORT CONTROLS
    const viewportFolder = gui.addFolder('viewport');
    viewportFolder.add(this, 'scaled').min(0.1).max(3).step(0.01);

    // FILTER SHADER
    const filterFolder = gui.addFolder('filter');
    filterFolder.add(this.shader, 'curvature').min(0).max(10).step(0.01);
    filterFolder.add(this.shader, 'lineWidth').min(0).max(10).step(0.01);
    filterFolder.add(this.shader, 'lineContrast').min(0).max(10).step(0.01);
    filterFolder.add(this.shader, 'verticalLine');
    filterFolder.add(this.shader, 'noise').min(0).max(10).step(0.01);
    filterFolder.add(this.shader, 'noiseSize').min(0).max(10).step(0.01);
    filterFolder.add(this.shader, 'vignetting').min(0).max(10).step(0.01);
    filterFolder.add(this.shader, 'vignettingAlpha').min(0).max(10).step(0.01);
    filterFolder.add(this.shader, 'vignettingBlur').min(0).max(10).step(0.01);
    filterFolder.add(this, 'speed').min(0).max(1).step(0.01);
    filterFolder.add(this.shader, 'seed').min(0).max(10).step(0.01);
  }
}
