import { Geometry, Mesh, Shader } from 'pixi.js';

import backgroundFragment from '@shaders/background.frag';
import backgroundVertex from '@shaders/background.vert';
import { gui } from '@classes/GUI';

export default class Background {
  constructor({ pixiApp, sizes }) {
    this.pixiApp = pixiApp;
    this.sizes = sizes;

    this.show();

    if (gui) this.createDebug();
  }

  show() {
    const geometry = new Geometry()
      .addAttribute(
        'aVertexPosition',
        [
          -window.innerWidth / 2,
          -window.innerHeight / 2,
          window.innerWidth / 2,
          -window.innerHeight / 2,
          window.innerWidth / 2,
          window.innerHeight / 2,
          -window.innerWidth / 2,
          window.innerHeight / 2,
        ],
        2,
      )
      .addAttribute('aUvs', [0, 0, 1, 0, 1, 1, 0, 1], 2)
      .addIndex([0, 1, 2, 0, 2, 3]);

    const vertexSrc = backgroundVertex;
    const fragmentSrc = backgroundFragment;

    const uniforms = {
      uTime: 0,
      iResolution: [window.innerWidth, window.innerHeight],
      uFadeAmount: 0,
      uSpeed: 1,
    };

    const shader = Shader.from(vertexSrc, fragmentSrc, uniforms);

    this.quad = new Mesh(geometry, shader);
    this.quad.position.set(window.innerWidth / 2, window.innerHeight / 2);

    this.pixiApp.stage.addChild(this.quad);

    this.pixiApp.ticker.add(this.update.bind(this));
  }

  hide() {
    const fadeFunction = delta => {
      const fadeIncrement = 0.03 * delta;
      this.quad.shader.uniforms.uFadeAmount += fadeIncrement;

      if (this.quad.shader.uniforms.uFadeAmount >= 1) {
        this.pixiApp.ticker.remove(fadeFunction);
        this.destroy();
      }
    };

    this.pixiApp.ticker.add(fadeFunction);
  }

  update(delta) {
    this.quad.shader.uniforms.uTime += (1 / 60) * delta * 0.5;
  }

  onResize() {}

  createDebug() {
    const backgroundFolder = gui.addFolder('background');
    backgroundFolder
      .add(this.quad.shader.uniforms, 'uSpeed')
      .min(0)
      .max(3)
      .step(0.1)
      .name('speed');
  }

  destroy() {
    this.pixiApp.ticker.remove(this.update);
    this.quad.visible = false;
  }
}
