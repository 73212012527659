import { easeCustomIn, easeCustomOut } from '@utils/easing';

import Button from '@animations/Button';
import Component from '@classes/Component';
import GSAP from 'gsap';
import each from 'lodash/each';
import { split } from '@utils/text';

export default class Intro extends Component {
  constructor() {
    super({
      element: '.intro',

      elements: {
        content: '.intro__content',
        button: '.intro__button',
        buttonWhite: '.intro__button--white',
        logo: '.intro__logo',
      },
    });

    this.timeline = GSAP.timeline({
      onReverseComplete: () => {
        this.emit('ready');
        this.destroy();
      },
    });

    this.createAnimation();
    this.addEventListeners();
  }

  createAnimation() {
    this.animations = {};

    each(
      [...this.elements.button, this.elements.buttonWhite],
      (element, index) => {
        this.animations[`button_${index}`] = new Button({ element });
      },
    );

    this.elements.contentSpans = split({
      append: true,
      element: this.elements.content,
      expression: '<br>',
    });
    each(this.elements.contentSpans, element => {
      split({
        append: false,
        element,
        expression: ' ',
      });
    });
  }

  show() {
    this.timeline
      .to(this.element, {
        duration: 3,
        autoAlpha: 1,
        ease: easeCustomIn,
      })

      .to(
        this.elements.logo,
        {
          duration: 5,
          autoAlpha: 1,
          ease: easeCustomIn,
        },
        '< 0.5',
      )

      .fromTo(
        this.elements.contentSpans,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 3,
          ease: easeCustomIn,
          stagger: {
            each: 0.1,
            from: 'random',
          },
        },
        '< 1',
      )

      .fromTo(
        this.elements.button,
        {
          autoAlpha: 0,
          y: '15%',
        },
        {
          duration: 2,
          autoAlpha: 1,
          y: '0%',
          ease: easeCustomIn,
          stagger: 0.3,
        },
        '< 1',
      )

      .fromTo(
        this.animations['button_0'].spans,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 1.5,
          ease: easeCustomOut,
          stagger: {
            each: 0.1,
            from: 'start',
          },
        },
        '<',
      )

      .fromTo(
        this.animations['button_1'].spans,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 1.5,
          ease: easeCustomOut,
          stagger: {
            each: 0.1,
            from: 'start',
          },
        },
        '<',
      )

      .fromTo(
        this.elements.buttonWhite,
        {
          autoAlpha: 0,
          y: '15%',
        },
        {
          duration: 2,
          autoAlpha: 1,
          y: '0%',

          ease: easeCustomIn,
        },
        '< 0.6',
      )

      .fromTo(
        this.animations['button_2'].spans,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 1.5,
          ease: easeCustomOut,
          stagger: {
            each: 0.1,
            from: 'start',
          },
          onStart: () => {
            this.emit('footer');
          },
        },
        '<',
      );
  }

  hide() {
    this.timeline.timeScale(3).reverse();
  }

  handleClick() {
    this.elements.buttonWhite.classList.add('disable');
    this.hide();
  }

  addEventListeners() {
    this.elements.buttonWhite.addEventListener('click', this.handleClick);
  }

  removeEventListeners() {
    this.elements.buttonWhite.removeEventListener('click', this.handleClick);
  }

  destroy() {
    this.removeEventListeners();
    this.timeline.kill();
    this.element.parentNode.removeChild(this.element);
  }
}
