import Component from '@classes/Component';
import GSAP from 'gsap';
import { GlowFilter } from '@pixi/filter-glow';
import { easeCustomOut } from '@utils/easing';
import { gui } from '@classes/GUI';
import state from '@store/index';

export default class ObjectManager extends Component {
  constructor(textureAtlas, sprites, controls) {
    super({
      element: '.object',
      elements: {
        items: '.object__item',
        win: '.object__win',
        ggwp: '#ggwp',
        photos: '#photos',
        gun: '#gun',
        note: '#note',
        necklace: '#necklace',
        ice: '#ice',
        glass: '#glass',
        can: '#can',
        hands: '#hands',
        iris: '#iris',
        tattoos: '#tattoos',
        six: '#six',
      },
    });

    this.textureAtlas = textureAtlas;
    this.sprites = sprites;
    this.controls = controls;

    this.init();
    this.createShaders();
    this.createAnimations();
    this.addEventListeners();

    if (gui) this.createDebug();
  }

  init() {
    const fragment = document.createDocumentFragment();
    const button = document.createElement('button');
    button.classList.add('object__close');
    button.setAttribute('data-link', 'close');

    button.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" data-link-arrow height="16" width="12" viewBox="0 0 384 512"><path fill="#000000" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>';

    fragment.appendChild(button);

    this.elements.items.forEach(element => {
      if (element.id === 'ggwp') return;
      element.appendChild(fragment.cloneNode(true));
    });

    this.sprites.forEach(sprite => {
      const spriteObject = this.textureAtlas.getSprite(sprite);
      spriteObject.eventMode = 'static';
      spriteObject.cursor = 'pointer';

      spriteObject.on('pointerup', () => {
        if (this.controls.isDragging) return;
        this.handleSpriteClick(sprite, spriteObject);
      });

      spriteObject.on('pointerover', () => {
        const shader = this.shaders[sprite];
        if (shader) {
          GSAP.killTweensOf(shader);
          GSAP.to(shader, {
            duration: 0.5,
            onStart: () => {
              spriteObject.filters = [shader];
            },
            outerStrength: 1,
            innerStrength: 0,
            ease: 'power2.out',
          });
        }
      });

      spriteObject.on('pointerout', () => {
        const shader = this.shaders[sprite];
        if (shader) {
          GSAP.killTweensOf(shader);
          GSAP.to(shader, {
            duration: 0.5,
            outerStrength: 0,
            innerStrength: 0,
            ease: 'power2.out',
            onComplete: () => {
              spriteObject.filters = [];
            },
          });
        }
      });
    });
  }

  createShaders() {
    this.shaders = {};
    this.sprites.forEach(sprite => {
      this.shaders[sprite] = new GlowFilter({
        color: 0xffffff,
        outerStrength: 0,
        innerStrength: 0,
        // distance: 0.5,
        // quality: 0.5,
      });
    });
  }

  createAnimations() {
    // this.animations = {};
    // each(this.elements, element => {
    //   const name = element.id;
    //   element.querySelectorAll('[data-animation]').forEach(element => {
    //     const animation = element.dataset.animation;
    //     if (animation === 'paragraph') {
    //       this.animations[`paragraph_${name}`] = new Text({
    //         append: false,
    //         element,
    //         delay: 2,
    //       });
    //     }
    //   });
    // });
  }

  #initTimeline() {
    this.timeline = GSAP.timeline({
      paused: true,
      onReverseComplete: () => {
        this.timeline.kill();
        this.timeline.clear();

        if (state.allObjectsFound) {
          this.#onAllObjectsFound();
        }
      },
    });
  }

  #showQuote(sprite) {
    this.#initTimeline();

    this.timeline
      .to(this.element, {
        duration: 1,
        autoAlpha: 1,
        ease: easeCustomOut,
      })
      .to(
        this.elements[sprite],
        {
          autoAlpha: 1,
          duration: 2,
          ease: easeCustomOut,
        },
        '< 0.75',
      );

    this.timeline.play();

    // this.animations[`title_${sprite}`].animateIn();
    // this.animations[`paragraph_${sprite}`].animateIn();
  }

  #onAllObjectsFound() {
    this.timeline = GSAP.timeline({ delay: 0.5 })
      .to(this.element, {
        duration: 1,
        autoAlpha: 1,
        ease: 'power2.out',
      })
      .to(
        this.elements.ggwp,
        {
          duration: 1,
          autoAlpha: 1,
          ease: 'power2.out',
        },
        '<',
      );
  }

  handleSpriteClick(sprite, spriteObject) {
    this.#showQuote(sprite);
    state.collectObject(sprite);
    spriteObject.filters = [];
    spriteObject.cursor = 'default';
    spriteObject.off('pointerover');
    spriteObject.off('pointerout');
    spriteObject.off('pointerup');
  }

  handleReverseTimeline() {
    this.timeline.timeScale(3).reverse();
    if (state.allObjectsFound) {
      this.removeEventListeners();
      this.element.classList.add('end');
    }
  }

  createDebug() {
    // // FILTER SHADER
    // const objectsFolder = gui.addFolder('glow');
    // objectsFolder.add(this.shader, 'innerStrength').min(0).max(20).step(0.01);
    // objectsFolder.add(this.shader, 'outerStrength').min(0).max(20).step(0.01);
    // objectsFolder.addColor(this.shader, 'color');
  }

  addEventListeners() {
    this.element.addEventListener('click', this.handleReverseTimeline);
  }

  removeEventListeners() {
    this.element.removeEventListener('click', this.handleReverseTimeline);
  }
}
