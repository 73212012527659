import { BaseTexture, Rectangle, Sprite, Texture } from 'pixi.js';

import { Container } from 'pixi.js';

export default class TextureAtlas {
  constructor(atlasJson, atlasImage, pixiApp) {
    this.sprites = {};
    this.spritesArray = [];
    this.pixiApp = pixiApp;
    this.loadSprites(atlasJson, atlasImage);
    this.setPositions();
  }

  loadSprites(atlasJson, atlasImage) {
    const baseTexture = BaseTexture.from(atlasImage);
    Object.keys(atlasJson.frames).forEach(key => {
      this.sprites[key.replace('.png', '').toLowerCase()] = this.createSprite(
        baseTexture,
        atlasJson.frames[key],
      );
    });
  }

  createSprite(baseTexture, frameData) {
    const rect = new Rectangle(
      frameData.frame.x,
      frameData.frame.y,
      frameData.frame.w,
      frameData.frame.h,
    );
    const texture = new Texture(baseTexture, rect);
    const sprite = new Sprite(texture);
    sprite.anchor.set(0.5);

    if (frameData.trimmed) {
      sprite.x =
        frameData.spriteSourceSize.x + frameData.spriteSourceSize.w * 0.5;
      sprite.y =
        frameData.spriteSourceSize.y + frameData.spriteSourceSize.h * 0.5;
    }

    return sprite;
  }

  setPositions() {
    const bg = this.sprites.background;
    bg.x = this.pixiApp.screen.width / 2;
    bg.y = this.pixiApp.screen.height / 2;
    const spriteIds = [
      'photos',
      'gun',
      'six',
      'glass',
      'can',
      'hands',
      'necklace',
      'ice',
      'note',
      'tattoos',
      'lips',
      'iris',
    ];
    spriteIds.forEach(id => this.setPosition(id, bg));
  }

  setPosition(spriteId, bg) {
    const sprite = this.sprites[spriteId];
    sprite.position.set(
      bg.position.x - bg.width / 2 + sprite.x,
      bg.position.y - bg.height / 2 + sprite.y,
    );
  }

  getSprite(id) {
    return this.sprites[id];
  }

  addToStage(stage) {
    const container = new Container();
    Object.values(this.sprites).forEach(sprite => {
      this.spritesArray.push(sprite);
      container.addChild(sprite);
    });

    stage.addChild(container);
  }
}
