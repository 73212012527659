import UAParser from 'ua-parser-js';

class DetectionManager {
  constructor() {
    this.deviceType = {
      Desktop: 'desktop',
      Phone: 'phone',
      Tablet: 'tablet',
    };

    this.parser = new UAParser();
    this.type = this.determineDeviceType(this.parser.getDevice().type);

    this.isMobile = this.type !== this.deviceType.Desktop;
    this.isPhone = this.type === this.deviceType.Phone;
    this.isTablet = this.type === this.deviceType.Tablet;
    this.isDesktop = this.type === this.deviceType.Desktop;

    if (
      typeof window.getComputedStyle(document.body).mixBlendMode === 'undefined'
    ) {
      this.isMixBlendModeUnsupported = true;
      document.documentElement.className += ' mix-blend-mode-unsupported';
    }

    this.setHTMLClass();
  }

  determineDeviceType(deviceType) {
    if (deviceType === 'mobile') {
      return this.deviceType.Phone;
    } else if (Object.values(this.deviceType).includes(deviceType)) {
      return deviceType;
    } else {
      return this.deviceType.Desktop;
    }
  }

  setHTMLClass() {
    const htmlElement = document.documentElement;
    htmlElement.classList.add(this.isMobile ? 'mobile' : 'desktop');
  }

  isWebGLAvailable() {
    if (!this.webGLAvailable) {
      const canvas = document.createElement('canvas');
      this.webGLAvailable =
        !!window.WebGLRenderingContext &&
        !!(
          canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
        );
    }
    return this.webGLAvailable;
  }

  isWebPSupported() {
    if (this.webPSupported === false) {
      const element = document.createElement('canvas');
      if (element.getContext('2d')) {
        this.webPSupported = element
          .toDataURL('image/webp')
          .startsWith('data:image/webp');
      } else {
        this.webPSupported = false;
      }
    }
    return this.webPSupported;
  }

  isAppBrowser() {
    const ua = navigator.userAgent;
    return /FBAN|FBAV|Twitter/.test(ua);
  }

  check({ onErrorWebGL, onSuccess }) {
    if (!this.isWebGLAvailable()) {
      onErrorWebGL();
    } else {
      onSuccess();
    }
  }
}

export const Detection = new DetectionManager();
