import { easeCustomIn, easeCustomOut } from '@utils/easing';

import { Assets } from 'pixi.js';
import Component from '@classes/Component';
import GSAP from 'gsap';
import { Howl } from 'howler';
import manifest from '@core/manifest.js';

export default class Loader extends Component {
  constructor() {
    super({
      element: '.preloader',
      elements: {
        wrapper: '.preloader__wrapper',
        fill: '.preloader__progressFill',
        start: '.preloader__start',
        bar: '.preloader__progressBar',
        description: '.preloader__description',
        container: '.preloader__container',
        pulse: '.preloader__pulse',
        number: '.preloader__number',
      },
    });

    this.loadedResources = 0;

    this.createPreloader();
    this.addEventListeners();
  }

  async createPreloader() {
    const audioFiles = [{ name: 'sound1', src: '/assets/audio/ambiance.mp3' }];

    const mainBundle = manifest.bundles.find(bundle => bundle.name === 'main');
    const graphicAssetsCount = mainBundle ? mainBundle.assets.length : 0;

    this.totalResources = graphicAssetsCount + audioFiles.length;

    await Assets.init({ manifest });
    await Assets.loadBundle('main', () => {
      this.updateProgress();
    });

    audioFiles.forEach(audio => {
      new Howl({
        src: [audio.src],
        onload: this.updateProgress,
        onloaderror: (id, err) => {
          console.log(err);
        },
      });
    });
  }

  updateProgress = () => {
    this.loadedResources++;
    const progress = this.loadedResources / this.totalResources;
    const percent = Math.floor(progress * 100);

    this.elements.fill.style.width = percent + '%';
    this.elements.number.textContent = percent + '%';

    if (progress === 1) {
      this.onLoaded();
    }
  };

  onLoaded() {
    return new Promise(resolve => {
      this.emit('completed');

      this.animateIn = GSAP.timeline({
        delay: 1.5,
        cursor: 'default',
        onStart: () => {
          resolve();

          document.body.style.cursor = 'default';
        },
        onComplete: () => {
          document.body.style.cursor = 'auto';
        },
      });

      this.animateIn
        .to(this.elements.bar, {
          scaleX: 0,
          duration: 1,
          ease: easeCustomIn,
        })

        .to(
          this.elements.number,
          {
            autoAlpha: 0,
            duration: 1,
            ease: easeCustomOut,
          },
          '<',
        )

        .to(this.elements.container, {
          duration: 3,
          autoAlpha: 1,
          delay: 0.35,
          ease: easeCustomIn,
          cursor: 'pointer',
        })

        .to(
          this.elements.description,
          {
            autoAlpha: 0,
            ease: easeCustomIn,
            duration: 1,
            repeat: -1,
            yoyo: true,
          },
          '<',
        );
    });
  }

  hide() {
    this.animateOut = GSAP.timeline()
      .to(this.elements.container, {
        duration: 1.5,
        autoAlpha: 0,
        ease: easeCustomIn,
        cursor: 'default',
        overwrite: true,
      })

      .to(this.element, {
        duration: 3,
        opacity: 0,
        ease: easeCustomOut,
        pointerEvents: 'none',
        onStart: () => {
          new Howl({
            src: ['assets/audio/ambiance.mp3'],
            autoplay: true,
            loop: true,
            preload: true,
            volume: 0.4,
          });

          this.emit('start');
        },
      });

    this.animateOut.call(() => {
      this.destroy();
    });
  }

  addEventListeners() {
    this.elements.container.addEventListener('click', this.hide);
  }

  removeEventListeners() {
    this.elements.container.removeEventListener('click', this.hide);
  }

  destroy() {
    this.removeEventListeners();
    this.animateIn.kill();
    this.animateOut.kill();

    this.element.parentNode.removeChild(this.element);
  }
}
