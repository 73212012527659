import { split } from '@utils/text';

export default class Button {
  constructor({ element }) {
    this.spans = split({
      append: false,
      element: element,
      expression: '',
    });
  }
}
