import '@scss/main.scss';
import '@utils/polyfill';

import AutoBind from 'auto-bind';
import Canvas from '@canvas/index';
import Cursor from '@components/Cursor';
import { Detection } from '@classes/Detection';
import FontFaceObserver from 'fontfaceobserver';
import Intro from '@components/Intro';
import Layout from '@components/Layout';
import Loader from '@components/Loader';
import Responsive from '@classes/Responsive';
import Stats from 'stats.js';
import { each } from 'lodash';

class App {
  constructor() {
    if (import.meta.env.DEV) {
      this.createStats();
    }

    AutoBind(this);

    this.mouse = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    };

    this.createResponsive();
    this.createCanvas();
    this.createCursor();
    this.createIntro();
    this.createLayout();

    Detection.check({
      onErrorWebGL: this.createWebGLScreen,
      onSuccess: this.createPreloaderScreen,
    });

    this.addEventListeners();
    this.addLinkListeners();

    this.update();
  }

  createPreloaderScreen() {
    this.loader = new Loader();
    this.loader.once('start', this.handleStart);
  }

  createWebGLScreen() {
    throw new Error('WebGL is not supported');
  }

  createCanvas() {
    this.canvas = new Canvas(this.responsive);
  }

  createResponsive() {
    this.responsive = new Responsive();
  }

  createIntro() {
    this.intro = new Intro();
    this.intro.once('ready', this.handleReady);
    this.intro.once('footer', this.handleFooter);
  }

  createLayout() {
    this.layout = new Layout();
    this.layout.on('dom:loaded', () => {
      if (this.cursor) this.cursor.onPreloadComplete();
    });
  }

  createCursor() {
    if (Detection.isDesktop) {
      this.cursor = new Cursor({
        size: 10,
      });
    }
  }

  handleStart() {
    this.intro.show();
    this.canvas.createBackground();
    this.createAnalytics();
  }

  handleReady() {
    this.canvas.createAtlas();
    this.layout.showHeader();
  }

  handleFooter() {
    this.layout.showFooter();
  }

  /**
   * Stats.
   */
  createStats() {
    this.stats = new Stats();
    document.body.appendChild(this.stats.dom);
  }

  /**
   * Analytics.
   */
  createAnalytics() {
    if (window.fathom) {
      const elements = [
        'book',
        'popup',
        'start',
        'facebook',
        'instagram',
        'tiktok',
        'bmr',
        'wallpapers',
      ];
      elements.forEach(id => {
        document.getElementById(id).addEventListener('click', () => {
          window.fathom.trackEvent(id);
        });
      });
    }
  }

  /**
   * Loop.
   */
  update() {
    if (this.stats) {
      this.stats.begin();
    }

    if (this.canvas) {
      this.canvas.update();
    }

    if (this.cursor) {
      this.cursor.update();
    }

    if (this.stats) {
      this.stats.end();
    }

    this.frame = window.requestAnimationFrame(() => this.update());
  }

  /**
   * Events.
   */

  onContextMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    return false;
  }

  onResize() {
    this.responsive.onResize();

    if (this.cursor) {
      this.cursor.onResize(this.responsive);
    }

    window.requestAnimationFrame(() => {
      if (this.canvas && this.canvas.onResize) {
        this.canvas.onResize(this.responsive);
      }
    });
  }

  onTouchUp(event) {
    event.stopPropagation();

    this.mouse.x = event.changedTouches
      ? event.changedTouches[0].clientX
      : event.clientX;
    this.mouse.y = event.changedTouches
      ? event.changedTouches[0].clientY
      : event.clientY;

    if (this.cursor) {
      this.cursor.onTouchUp();
    }
  }

  onTouchMove(event) {
    event.stopPropagation();

    this.mouse.x = event.touches ? event.touches[0].clientX : event.clientX;
    this.mouse.y = event.touches ? event.touches[0].clientY : event.clientY;

    if (this.cursor) {
      this.cursor.onTouchMove(this.mouse);
    }
  }

  onTouchDown(event) {
    event.stopPropagation();

    this.mouse.x = event.touches ? event.touches[0].clientX : event.clientX;
    this.mouse.y = event.touches ? event.touches[0].clientY : event.clientY;

    if (event.target.tagName === 'A' || event.target.tagName === 'BUTTON') {
      return;
    }

    if (this.cursor) {
      this.cursor.onTouchDown();
    }
  }

  /***
   * Listeners.
   */
  addEventListeners() {
    window.addEventListener('resize', this.onResize, { passive: true });

    window.addEventListener('mousedown', this.onTouchDown, { passive: true });
    window.addEventListener('mousemove', this.onTouchMove, { passive: true });
    window.addEventListener('mouseup', this.onTouchUp, { passive: true });

    window.addEventListener('touchstart', this.onTouchDown, { passive: true });
    window.addEventListener('touchmove', this.onTouchMove, { passive: true });
    window.addEventListener('touchend', this.onTouchUp, { passive: true });

    if (Detection.isMobile) {
      window.oncontextmenu = this.onContextMenu;
    }
  }

  addLinkListeners() {
    const links = document.querySelectorAll('a');
    each(links, link => {
      const isLocal = link.href.indexOf(window.location.origin) > -1;
      const isAccessibility = link.href.indexOf('/accessibility') > -1;
      const isNotEmail = link.href.indexOf('mailto') === -1;
      const isNotPhone = link.href.indexOf('tel') === -1;
      const isNotDownload = link.href.indexOf('download') === -1;
      if (isLocal && !isAccessibility && !isNotDownload) {
        link.onclick = event => {
          event.preventDefault();
          this.onChange({
            url: link.href,
          });
        };
      } else if (
        (isNotEmail && isNotPhone && isNotDownload) ||
        isAccessibility
      ) {
        link.rel = 'noopener';
        link.target = '_blank';
      }
    });
  }
}

const gotham = new FontFaceObserver('gotham');
const frunchy = new FontFaceObserver('frunchy');

const timeout = 2000;

Promise.all([gotham.load(null, timeout), frunchy.load(null, timeout)])
  .then(() => {
    window.APP = new App();
  })
  .catch(() => {
    window.APP = new App();
  });
