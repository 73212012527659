import CustomEase from 'gsap/CustomEase';
import GSAP from 'gsap';
GSAP.registerPlugin(CustomEase);

export const easy = x => {
  return 1 - Math.pow(1 - x, 5);
};

// in
export const easeInQuad = CustomEase.create(
  'easeInQuad',
  '0.55, 0.085, 0.68, 0.53',
);
export const easeInCubic = CustomEase.create(
  'easeInCubic',
  '0.55, 0.055, 0.675, 0.19',
);
export const easeInQuart = CustomEase.create(
  'easeInQuart',
  '0.895, 0.03, 0.685, 0.22',
);
export const easeInQuint = CustomEase.create(
  'easeInQuint',
  '0.755, 0.05, 0.855, 0.06',
);
export const easeInSine = CustomEase.create(
  'easeInSine',
  '0.47, 0, 0.745, 0.715',
);
export const easeInExpo = CustomEase.create(
  'easeInExpo',
  '0.95, 0.05, 0.795, 0.035',
);
export const easeInCirc = CustomEase.create(
  'easeInCirc',
  '0.6, 0.04, 0.98, 0.335',
);
export const easeInBack = CustomEase.create(
  'easeInBack',
  '0.6, 0, 0.735, 0.045',
);

// out
export const easeOutQuad = CustomEase.create(
  'easeOutQuad',
  '0.25, 0.46, 0.45, 0.94',
);
export const easeOutCubic = CustomEase.create(
  'easeOutCubic',
  '0.215, 0.61, 0.355, 1',
);
export const easeOutQuart = CustomEase.create(
  'easeOutQuart',
  '0.165, 0.84, 0.44, 1',
);
export const easeOutQuint = CustomEase.create(
  'easeOutQuint',
  '0.23, 1, 0.32, 1',
);
export const easeOutSine = CustomEase.create(
  'easeOutSine',
  '0.39, 0.575, 0.565, 1',
);
export const easeOutExpo = CustomEase.create('easeOutExpo', '0.19, 1, 0.22, 1');
export const easeOutCirc = CustomEase.create(
  'easeOutCirc',
  '0.075, 0.82, 0.165, 1',
);
export const easeOutBack = CustomEase.create(
  'easeOutBack',
  '0.175, 0.885, 0.32, 1',
);

// in-out
export const easeInOutQuad = CustomEase.create(
  'easeInOutQuad',
  '0.455, 0.03, 0.515, 0.955',
);
export const easeInOutCubic = CustomEase.create(
  'easeInOutCubic',
  '0.645, 0.045, 0.355, 1',
);
export const easeInOutQuart = CustomEase.create(
  'easeInOutQuart',
  '0.77, 0, 0.175, 1',
);
export const easeInOutQuint = CustomEase.create(
  'easeInOutQuint',
  '0.86, 0, 0.07, 1',
);
export const easeInOutSine = CustomEase.create(
  'easeInOutSine',
  '0.445, 0.05, 0.55, 0.95',
);
export const easeInOutExpo = CustomEase.create('easeInOutExpo', '1, 0, 0, 1');
export const easeInOutCirc = CustomEase.create(
  'easeInOutCirc',
  '0.785, 0.135, 0.15, 0.86',
);
export const easeInOutBack = CustomEase.create(
  'easeInOutBack',
  '0.68, 0, 0.265, 1',
);

// custom
export const DEFAULT = CustomEase.create('default', '0.77, 0, 0.175, 1');
export const easeCustomIn = CustomEase.create('easeCustomIn', '0.4, 0, 0.1, 1');
export const easeCustomOut = CustomEase.create(
  'easeCustomOut',
  '0.3, 0.1, 0.05, 1',
);
export const easeBounce = CustomEase.create(
  'easeBounce',
  '0.47, 1.64, 0.41, 0.8',
);

export const easeCustom = CustomEase.create(
  'custom',
  'M0,0 C0.126,0.382 0.277,0.674 0.436,0.767 0.764,0.997 1,1 1,1',
);
