import { easeCustomIn, easeCustomOut } from '@utils/easing';

import Component from '@classes/Component';
import GSAP from 'gsap';
import { Howler } from 'howler';
import state from '@store/index';
import { subscribe } from 'valtio';

export default class Layout extends Component {
  constructor() {
    super({
      element: '.layout',

      elements: {
        header: '.header',
        footer: '.footer',
        sound: '.controls__sound',
        soundBars: '.playing-icon',
        count: '.header__count',
        legals: '.footer__link',
        zoom: '.zoom',
        zoomProgress: '.zoom__progress',
        zoomFill: '.zoom__fill',
      },
    });

    this.fadeInterval = null;
    this.userMuted = false;
    this.zoomLevel = 0;

    this.addEventListeners();

    subscribe(state, () => {
      if (state.zoomLevel !== this.zoomLevel) {
        this.zoomLevel = state.zoomLevel;
        this.updateZoom();
      }
    });
  }

  showFooter() {
    GSAP.to(this.elements.footer, {
      duration: 2,
      autoAlpha: 1,
      ease: easeCustomIn,
    });
  }

  showHeader() {
    this.headerTimeline = GSAP.timeline({ delay: 2.5 });

    this.headerTimeline
      .to(this.elements.header, {
        duration: 2,
        autoAlpha: 1,
        ease: easeCustomIn,
      })
      .to(
        this.elements.zoom,
        {
          duration: 2,
          autoAlpha: 1,
          ease: easeCustomIn,
          onComplete: () => {
            this.emit('dom:loaded');
          },
        },
        '<',
      );
  }

  addEventListeners() {
    subscribe(state, () => {
      if (state.count < 10) {
        this.elements.count.textContent = '0' + state.count.toString();
      } else {
        this.elements.count.textContent = state.count.toString();
      }
    });

    this.elements.legals.addEventListener('click', () => {
      document.querySelector('.legals').classList.add('active');
    });

    document.querySelector('.legals__close').addEventListener('click', () => {
      document.querySelector('.legals').classList.remove('active');
    });

    let isSoundMuted = false;

    this.elements.soundBars.addEventListener('click', () => {
      if (isSoundMuted) {
        this.elements.soundBars.classList.remove('muted');
      } else {
        this.elements.soundBars.classList.add('muted');
      }

      isSoundMuted = !isSoundMuted;
    });

    this.elements.sound.addEventListener('click', () => {
      this.toggleAudioVolume();
    });

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        this.fadeOutVolume();
      } else if (!this.userMuted) {
        this.fadeInVolume();
      }
    });
  }

  updateZoom() {
    this.elements.zoomFill.style.transform = `scaleY(${this.zoomLevel})`;
  }

  toggleAudioVolume() {
    if (Howler.volume() > 0) {
      this.fadeOutVolume();
      this.userMuted = true;
    } else {
      this.fadeInVolume();
      this.userMuted = false;
    }
  }

  fadeOutVolume() {
    clearInterval(this.fadeInterval);
    this.fadeInterval = setInterval(() => {
      if (Howler.volume() > 0) {
        Howler.volume(Math.max(Howler.volume() - 0.1, 0));
      } else {
        clearInterval(this.fadeInterval);
      }
    }, 100);
  }

  fadeInVolume() {
    clearInterval(this.fadeInterval);
    this.fadeInterval = setInterval(() => {
      if (Howler.volume() < 1) {
        Howler.volume(Math.min(Howler.volume() + 0.1, 1));
      } else {
        clearInterval(this.fadeInterval);
      }
    }, 100);
  }
}
