import { proxy } from 'valtio';

const store = proxy({
  count: 0,
  collectedObjects: {
    photos: false,
    six: false,
    gun: false,
    necklace: false,
    note: false,
    ice: false,
    glass: false,
    can: false,
    hands: false,
    iris: false,
    tattoos: false,
  },

  allObjectsFound: false,
  zoomLevel: 0,

  incrementCounter: function () {
    this.count += 1;
  },

  collectObject: function (objectName) {
    if (!(objectName in this.collectedObjects)) {
      throw new Error(`Invalid object name: ${objectName}`);
    }
    if (!this.collectedObjects[objectName]) {
      this.collectedObjects[objectName] = true;
      this.incrementCounter();

      if (this.count === Object.keys(this.collectedObjects).length) {
        this.allObjectsFound = true;
      }
    }
  },
});

export default store;
