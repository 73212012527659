import ObjectManager from '@canvas/ObjectManager.js';
import TextureAtlas from '@canvas/TextureAtlas.js';
import atlas from '@data/atlas.json';
import atlasTexture from '/assets/textures/atlas.png';

export default class Painting {
  constructor({ pixiApp, sizes, controls }) {
    this.pixiApp = pixiApp;
    this.sizes = sizes;
    this.controls = controls;

    this.init();
  }

  init() {
    const textureAtlas = new TextureAtlas(atlas, atlasTexture, this.pixiApp);

    const sprites = [
      'photos',
      'six',
      'gun',
      'necklace',
      'note',
      'ice',
      'glass',
      'can',
      'hands',
      'iris',
      'tattoos',
    ];
    this.objectManager = new ObjectManager(
      textureAtlas,
      sprites,
      this.controls,
    );

    textureAtlas.addToStage(this.controls);

    this.controls.show();
  }
}
