import atlas from '/assets/textures/atlas.png';

export default {
  bundles: [
    {
      name: 'main',
      assets: [
        {
          name: 'atlas',
          srcs: atlas,
        },
      ],
    },
  ],
};
