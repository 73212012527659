import { Application } from 'pixi.js';
import Background from '@canvas/Background.js';
import Controls from '@canvas/Controls.js';
import EventEmitter from 'events';
import Painting from '@canvas/Painting.js';
import { delay } from '@utils/maths';

export default class Canvas extends EventEmitter {
  constructor({ size }) {
    super();

    this.sizes = {
      environment: {
        height: 2340,
        width: 4096,
      },
      retina: Math.min(window.devicePixelRatio, 2),
      ratio: size / 10,
      screen: {
        height: window.innerHeight,
        width: window.innerWidth,
      },
    };

    this.createPixiApp();
    this.createControls();
  }

  onPreloaded() {}

  createPixiApp() {
    this.pixiApp = new Application({
      width: window.innerWidth,
      height: window.innerHeight,
      antialias: false,
      view: document.querySelector('#webgl'),
      autoDensity: true,
      background: 0x000000,
      resizeTo: window,
      resolution: this.sizes.retina,
    });
  }

  createBackground() {
    this.background = new Background({
      pixiApp: this.pixiApp,
      sizes: this.sizes,
    });
  }

  createControls() {
    this.controls = new Controls({
      pixiApp: this.pixiApp,
      sizes: this.sizes,
    });
  }

  async createAtlas() {
    this.background.hide();

    await delay(1500);

    this.atlas = new Painting({
      pixiApp: this.pixiApp,
      sizes: this.sizes,
      controls: this.controls,
    });
  }

  createObjectManager() {}

  onResize({ sizes }) {
    this.sizes.screen = {
      height: sizes.height,
      width: sizes.width,
    };

    if (this.background) {
      this.background.onResize({ sizes });
    }

    if (this.controls) {
      this.controls.onResize({ sizes });
    }

    if (this.pixiApp) {
      this.pixiApp.renderer.resize(sizes.width, sizes.height);
    }
  }

  update() {}
}
